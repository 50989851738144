import { decode } from 'js-base64'

export const toPrice = (num, minVariantPrice) => Intl.NumberFormat(undefined, {
  currency: minVariantPrice ? minVariantPrice.currencyCode : 'USD',
  minimumFractionDigits: 2,
  style: 'currency',
}).format(num)

export const getProductName = product => {
	let name = false
	name = product.options && product.options.filter(option => option.name === 'Title')
	if (name && name[0]) {
		name = name[0].values[0]
	}
	if (name === 'Default Title') {
		name = false
		if (product.vendor) {
			name = product.vendor
		}
	}
	if (product.options && product.options.filter(option => option.name === 'Color')[0]) {
		name = product.options.filter(option => option.name === 'Color')[0].values[0]
	}
	return name
}

export const getProductVariant = product => {
	let name = false

	name = product.variant.selectedOptions.map(option => option.value !== 'Default Title' ? option.value : null)
	name = name.join()
	return name
}

export const getProductArrangement = product => {
	let arrangement = false
	if (product.options && product.options.filter(option => option.name === 'Arrangement')[0]) {
		arrangement = product.options.filter(option => option.name === 'Arrangement')[0].values[0]
	}
	return arrangement
}

export const decodeProductId = id => {
	// return decode(id).split('/').pop()
	return decode(id)
}

export const parseProductVariant = inputBase64 => {
  if (inputBase64) {
    // const decoded = decodeBase64(inputBase64)
    const str = inputBase64.replace('gid://shopify/ProductVariant/', '')
    return parseFloat(str)
  } else {
    return false
  }
}

export const parseProduct = inputBase64 => {
  if (inputBase64) {
    // const decoded = decodeBase64(inputBase64)
    const str = inputBase64.replace('gid://shopify/Product/', '')
    return parseFloat(str)
  } else {
    return false
  }
}
