import React, { Component } from 'react'
import styled from '@emotion/styled'
import { colors, typography, animations } from 'src/styles'
import Link from 'src/components/Link'
import NavUnderline from 'src/assets/images/nav-underline.svg'

const NavLinkUnderline = styled(NavUnderline)`
  display: block;
  position: absolute;
  bottom: -.4em;
  left: 0;
  right: 0;
  width: 100%;
  overflow: visible;
  height: .25em;
  * {
    fill: none;
    stroke-width: 1.5px;
    // stroke: inherit;
    stroke: ${ colors.mainColor };
		stroke: var(--main-color);
    stroke-dasharray: 200%;
    stroke-dashoffset: 200%;
    transition: stroke-dashoffset ${ animations.mediumSpeed } ease-in-out, stroke ${ animations.mediumSpeed } ease-in-out;
  }
  ${ ({ active }) => active ? `
    * {
      stroke-dasharray: 200%;
      stroke-dashoffset: 40%;
    }
  ` : '' }

`

const StyledLinkText = styled.div`
	position: relative;
	display: inline-block;
	~ {
		stroke: inherit;
	}

	// &:after {
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	top: 100%;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 2px;
	// 	background: ${ ({ theme }) => colors[theme] };
	// 	transform: scaleX(0);
	// 	transform-origin: right center;
	// 	transition: transform ${ animations.mediumSpeed } ease-in-out;
	// }
	// &:hover {
	// 	&:after {
	// 		transform-origin: left center;
	// 		transform: scaleX(1);
	// 	}
	// }
`

const TextLinkWrapper = styled(Link)`
	position: relative;
	${ typography.h6 }
	display: inline-block;
	&:hover {
    ${ NavLinkUnderline } {
      * {
        stroke-dasharray: 200%;
        stroke-dashoffset: 40%;
      }
    }
  }
`

class TextLink extends Component {
	render () {
		const {
			to,
			external,
			target,
			loading,
			error,
			success,
			disabled,
			onClick,
			theme,
			className,
			children,
			title,
			active = false
		} = this.props

		return (
			<TextLinkWrapper
				className={className}
				to={to}
				target={target}
				external={external}
				loading={loading}
				error={error}
				success={success}
				disabled={disabled}
				onClick={onClick}
				theme={theme}
				title={title}
			>
				<StyledLinkText>
					{children}
				</StyledLinkText>
				<NavLinkUnderline active={active}/>
			</TextLinkWrapper>
		)
	}
}

TextLink.defaultProps = {
	theme: 'currentcolor'
}

export default TextLink
