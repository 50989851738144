import React from 'react'
import styled from '@emotion/styled'
import ThemeSelector from 'src/components/ThemeSelector'
import { globals } from 'src/styles'
import themes from 'src/styles/themes'
import RoughEdge from 'src/components/RoughEdge'

const SectionWrapper = styled(ThemeSelector)`
	position: relative;
	width: 100%;
	z-index: ${ ({ zIndex }) => zIndex };
	${ ({ padded, prevTheme, nextTheme, setTheme, isFirstSection, padBottom, padTop }) => padded !== false && `
		${ padTop !== false && `
			${ !isFirstSection ? `
				${ setTheme === prevTheme ? `
					${ globals.verticalSpacing('padding-top', 0.5) }
				` : `
					${ globals.verticalSpacing('padding-top') }
				` }
			` : `
				${ globals.verticalSpacing('padding-top', 0.5) }
			` }
		` }
		${ padBottom !== false && `
			${ setTheme === nextTheme ? `
				${ globals.verticalSpacing('padding-bottom', 0.5) }
			` : `
				${ globals.verticalSpacing('padding-bottom') }
			` }
		` }
	` }
`

const SectionRoughEdge = styled(RoughEdge)`
	path {
		fill: ${ ({ setTheme }) => themes[setTheme].background };
	}
`

const Section = ({
	children,
	setTheme = 'default',
	prevTheme,
	nextTheme,
	zIndex,
	buttons,
	padded,
	sectionid,
	className,
	isFirstSection,
	bottomEdge,
	index,
	roughEdgeClip,
	padBottom,
	padTop,
	noEdge
}) => {
	return (
		<SectionWrapper
			className={className}
			setTheme={setTheme}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			zIndex={zIndex}
			padded={padded}
			padBottom={padBottom}
			padTop={padTop}
			isFirstSection={isFirstSection}
		>
			{!noEdge && (
				prevTheme !== setTheme || roughEdgeClip) && !isFirstSection && (
					<SectionRoughEdge className="section-rough-edge" setTheme={roughEdgeClip && prevTheme ? prevTheme : setTheme || 'default'} delay={index} shape={roughEdgeClip ? 'bottom' : 'top'} roughEdgeClip={roughEdgeClip} />
				)
			}
			{children}
			{bottomEdge && (
				<SectionRoughEdge className="section-rough-edge" position='bottom' setTheme={setTheme || 'default'} delay={index}/>
			)}
		</SectionWrapper>
	)
}

Section.defaultProps = {
	setTheme: 'bgColor',
	prevTheme: false,
	nextTheme: false,
	zIndex: 1,
	padded: true
}

export default Section
