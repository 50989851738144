import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import Video from 'src/components/Video'
import InlineUnderlineSvg from 'src/assets/images/inline-underline.svg'
import EyebrowUnderline from 'src/assets/images/ette-eyebrow-underline.svg'
import { colors, mq, typography } from 'src/styles'

const RichTextWrapper = styled.div`
	white-space: pre-wrap;
	* {
		white-space: pre-wrap;
	}
	p {
		min-height: 1em;
		&.last-item {
			margin-bottom: 0;
			&:empty {
				display: none;
			}
		}
	}
	> * {
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
			&:empty {
				display: none;
			}
		}
	}
	.table-wrapper {
		overflow: auto;
		${ mq.smallAndBelow } {
			padding: 0 calc(100vw / 14);
			margin: 0 calc(100vw / -14);
		}
	}
	table {
		${ mq.smallAndBelow } {
			min-width: 600px;
		}
		margin: 1em 0;
		border-bottom: 1px solid ${ colors.hrColor };
		tr {
			border-top: 1px solid ${ colors.hrColor };
			td {
				padding: 12px 0;
				p {
					${ typography.bodyTiny }
					margin: 0;
					b {
						font-weight: 600;
					}
				}
				&:not(:first-of-type) {
					border-left: 1px solid ${ colors.hrColor };
					padding-left: 12px;
					padding-right: 12px;
				}
			}
		}
	}
`

const InlineMedia = styled.div`
	display: block;
	${ ({ first }) =>
		first ? `
		margin-top: 0;
	` : `
		margin-top: 24px;
	` }
	${ ({ last }) =>
		last ? `
		margin-bottom: 0;
	` : `
		margin-bottom: 24px;
	` }
`

const UnderlineComponent = styled.div`
	display: inline-block;
	position: relative;
	svg {
		position: absolute;
		width: 100%;
		display: block;
		top: calc(100% - 0.1em);
		overflow: visible;
		height: 0.2em;
		* {
			fill: none;
			// stroke-width: 1.5px;
			stroke: ${ colors.mainColor };
		}
	}
`

const Hr = styled.div`
	height: 7px;
	margin: 20px 0;
	svg {
		display: inline-block;
		vertical-align: top;
		margin: 0 auto;
		width: 54px;
		* {
			fill: ${ colors.pink4 };
		}
	}
`

const renderUnderlineText = text => {
	return (
		<UnderlineComponent>
			<span>{text}</span>
			<InlineUnderlineSvg />
		</UnderlineComponent>
	)
}

const renderMedia = (file, image) => {
	if (file.contentType.includes('video')) {
		return <InlineMedia as={Video} src={file.url} />
	} else if (image) {
		return <InlineMedia as={GatsbyImage} image={image} alt={file.fileName} />
	} else {
		return false
	}
}

const options = (linkInNewTab, richText) => {
	const textOptions = {
		renderMark: {
			[MARKS.BOLD]: text => <b>{text}</b>,
			[MARKS.UNDERLINE]: text => renderUnderlineText(text),
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
			[BLOCKS.TABLE]: (node, children) => <div className="table-wrapper"><table>{children}</table></div>,
			[BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
			[BLOCKS.TABLE_HEADER_CELL]: (node, children) => <td>{children}</td>,
			[BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
			[BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
			[BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
			[BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
			[BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
			[BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
			[BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
			[BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
			[BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
			[BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
			[BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
			[BLOCKS.HR]: (node, children) => (
				<Hr>
					<EyebrowUnderline />
				</Hr>
			),
			[BLOCKS.EMBEDDED_ENTRY]: (node, children) => <div>{children}</div>,
			[BLOCKS.EMBEDDED_ASSET]: node => {
				const file = node.data.target.file
				const image = node.data.target.gatsbyImageData
				return <Fragment>{renderMedia(file, image)}</Fragment>
			},
			[INLINES.HYPERLINK]: (node, children) => (
				<a
					href={node.data.uri}
					target={linkInNewTab ? '_blank' : ''}
					rel={linkInNewTab ? 'noopener noreferrer' : ''}
				>
					{children}
				</a>
			),
		},
	}
	return textOptions
}

const ContentfulRichText = ({ richText, className, linkInNewTab = false }) => {
	if (!richText || !richText.raw) {
		return false
	}

	let rawObj = JSON.parse(richText.raw)

	rawObj.content[0].firstItem = true
	rawObj.content[rawObj.content.length - 1].lastItem = true

	rawObj = JSON.stringify(rawObj)

	// TODO move space from first and last pieces of content
	// let renderer know which is the first item
	// text[0].first = true
	// text[text.length - 1].last = true

	/* Temporarily remove funky logic
	if (text[text.length - 1].content[0].value === '' && text[text.length - 1].content.length === 1) {
		// remove last item if empty
		text.pop()
		// let renderer know which is the last item
		text[richText.content.length - 1].last = true
	} else {
		// let renderer know which is the last item
		text[richText.content.length - 1].last = true
	}
	*/

	return (
		<RichTextWrapper className={className}>
			{renderRichText(richText, options(linkInNewTab, richText))}
		</RichTextWrapper>
	)
}

// ContentfulRichText.defaultProps = {
// 	richText: richText
// }

export default ContentfulRichText
