import React from 'react'
import styled from '@emotion/styled'

import Line1 from 'src/assets/images/ette-mobile-menu-1.svg'
import Line2 from 'src/assets/images/ette-mobile-menu-2.svg'
import Line3 from 'src/assets/images/ette-mobile-menu-3.svg'

const transitionSpeed = '0.3s'

const Piece = styled.div`
	// height: 1em;
	// border: .5em solid currentcolor;
	width: 100%;
	background: transparent;
	position: absolute;
	left: 0;
	transform-style: preserve-3d;
	border-radius: ${ ({ rounded }) => (rounded ? '20px' : 0) };
	transition: transform ${ transitionSpeed } ease-in-out,
		top ${ transitionSpeed } ease-in-out, bottom ${ transitionSpeed } ease-in-out,
		transform ${ transitionSpeed } ease-in-out,
		width ${ transitionSpeed } ease-in-out, height ${ transitionSpeed } ease-in-out,
		opacity ${ transitionSpeed } ease-in-out, top ${ transitionSpeed } ease-in-out,
		left ${ transitionSpeed } ease-in-out, bottom ${ transitionSpeed } ease-in-out,
		right ${ transitionSpeed } ease-in-out,
		border-radius ${ transitionSpeed } ease-in-out,
		font-size ${ transitionSpeed } 3s ease-in-out,
		margin ${ transitionSpeed } ease-in-out,
		background ${ transitionSpeed } ease-in-out,
		border ${ transitionSpeed } ease-in-out,
		visibility ${ transitionSpeed } ease-in-out;
	${ ({ piece, mobileMenuOpen }) => `

		// PIECE 1
		${
			piece === 'one' ? `
			top: 0;
			${
				mobileMenuOpen === true ? `
				// menu open
				top: 40%;
				visibility: visible;
				transform: rotate(45deg);
			` : `
				// menu closed
				visibility: visible;
			`
			}
		` : ''
		}
		// PIECE 2
		${
			piece === 'two' ? `
			top: 40%;
			${
				mobileMenuOpen === true ? `
				// menu open
				visibility: hidden;
				opacity: 0;
				
			` : `
				// menu closed
				visibility: visible;
			`
			}
		` : ''
		}
		// PIECE 3
		${
			piece === 'three' ? `
			top: 80%;
			${
				mobileMenuOpen === true ? `
				// menu open
				top: 40%;
				transform: rotate(-45deg);
				visibility: visible;
			` : `
				// menu closed
				visibility: visible;
			`
			}
		` : ''
		}
	` }

	svg {
		display: block;
		width: 100%;
		height: auto;
		path {
			fill: currentcolor;
		}
	}
`

const Wrapper = styled.div`
	position: relative;
	transition: transform ${ transitionSpeed } ease-in-out;
	width: 30px;
	height: 18px;

	// display: flex;
	// flex-direction: column;
	// justify-content: space-between;
`

const CustomAnimatedIcon = ({ mobileMenuOpen, className }) => {
	return (
		<Wrapper className={className}>
			<Piece piece="one" mobileMenuOpen={mobileMenuOpen}>
				<Line1 />
			</Piece>
			<Piece piece="two" mobileMenuOpen={mobileMenuOpen}>
				<Line2 />
			</Piece>
			<Piece piece="three" mobileMenuOpen={mobileMenuOpen}>
				<Line3 />
			</Piece>
		</Wrapper>
	)
}

export default CustomAnimatedIcon
