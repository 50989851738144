import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { lighten, darken } from 'polished'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { typography, mq, colors, util } from 'src/styles'
import { responsiveStyles } from 'src/styles/util'
import { themes } from 'src/styles/themes'
import { decodeProductId } from 'src/utils/format'

import EyebrowUnderline from 'src/assets/images/ette-eyebrow-underline.svg'

export const underlineColors = {
	pink2: colors.pink2,
	pink: colors.mainColor,
	orange: lighten(0.15, colors.yellow),
	darkPink: colors.pink4,
	green: colors.lightGreen,
	themedefault: colors.pink4,
	themepink: colors.white,
	thememidPink: colors.white,
	themedarkPink: colors.white,
	themehoney: colors.orange,
	themeorange: colors.white,
	themelavender: colors.white,
	themeyellow: colors.honey
}

const Wrapper = styled.div`
	display: inline-block;
	display: block;
	vertical-align: top;
	${ ({ alignment }) =>
		alignment === 'center' &&
		`
		margin-left: auto;
		margin-right: auto;
		> div {
			margin-left: auto;
			margin-right: auto;
		}
	` }
	${ mq.mediumAndBelow } {
		display: block;
	}
`
const TextContainer = styled(ScrollEntrance)`
	text-align: ${ ({ alignment }) => alignment };
	width: 100%;
	${ ({ alignment }) =>
		alignment === 'center' &&
		`
		margin-left: auto;
		margin-right: auto;
		h1, h2, h3, h4, h5, h6, p, blockquote, ul {
			margin-left: auto;
			margin-right: auto;
		}
	` }
	${ ({ alignment }) =>
		alignment === 'right' &&
		`
		margin-left: auto;
		h1, h2, h3, h4, h5, h6, p, blockquote, ul {
			margin-left: auto;
		}
	` }
	${ ({ headlineStyle }) =>
		headlineStyle === 'handwritten' &&
		`
		h1, h2, h3 {
			${ responsiveStyles('font-size', 77, 55, 48, 41) }
			font-family: ${ typography.secondaryFont };
			text-transform: lowercase;
			letter-spacing: -0.0216em;
			line-height: .9em;
			max-width: 10em !important;
		}
	` }
`

const Eyebrow = styled.p`
	${ ({ hasText }) =>
		hasText ? `
		margin: 0 0 0.5em;
		// margin: 0;
	` : `
		margin: 0;
	` }

	${ ({ handwritten }) =>
		handwritten ? `
		${ typography.handwrittenEyebrow }
		margin: 0 0 -.15em;
	` : `
		${ typography.eyebrow }
		` }
`

const UnderlineWrap = styled.div`
	height: 7px;
	margin: 20px 0;
	${ util.responsiveStyles('margin-top', 32, 32, 28, 24) }
	${ ({ contentBelow }) => contentBelow ? `
		${ util.responsiveStyles('margin-bottom', 32, 32, 28, 24) }
	` : `
		margin-bottom: 0;
	` }
`

export const StyledUnderline = styled(EyebrowUnderline)`
	vertical-align: top;
	margin: 0 auto;
	${ ({ altUnderline }) => altUnderline && `
		transform: scaleX(-1);
	` }
	width: 54px;
	${ ({ underlineColor }) => underlineColor === 'none' ? `
		display: none;
	` : `
		display: inline-block;
	` }

	* {
		${ ({ underlineColor }) => `
				fill: ${ underlineColor || colors.mainColor };
		` }
	}
`

const Headline = styled.h3`
	${ ({ headlineSize }) => typography[headlineSize] }
	${ ({ hasText }) =>
		!hasText &&
		`
		margin-bottom: 0;
	` }
	${ ({ hasEyebrow }) =>
		!hasEyebrow &&
		`
		margin-top: 0;
	` }
`

const Text = styled.div`
	p {
		${ ({ textSize }) => typography[textSize] }
	}
`

const CustomTextLink = styled(TextLink)`
	${ typography.buttonStyle }
`

const ActionWrapper = styled.div`
	padding: 16px 10px 0;
	display: inline-block;
	vertical-align: middle;
`

const ButtonActions = styled.div`
	margin-top: 8px;
	text-align: ${ ({ alignment }) => alignment };
	margin-left: -10px;
	margin-right: -10px;
`

const TextLockup = ({
	theme = 'default',
	setTheme,
	eyebrow,
	handwrittenEyebrow,
	eyebrowUnderline = true,
	underlineColor = 'pink',
	headline,
	headlineSize,
	headlineElement,
	text,
	textSize,
	actions,
	className,
	icon,
	alignment,
	additions,
	entranceDelay,
	transitionIn,
	headlineStyle,
	altUnderline
}) => {
	const { allShopifyProduct } = useStaticQuery(
		graphql`
			query {
				allShopifyProduct {
			    edges {
			      node {
			        handle
			        shopifyId
			      }
			    }
			  }
			}
		`
	)

	const getProductLink = id => {
		const decodedId = decodeProductId(id)
		const product = allShopifyProduct?.edges?.filter(product => product.node.shopifyId === decodedId)[0]?.node
		return '/shop/' + product?.handle
	}

	return (
		<Wrapper className={className} alignment={alignment}>
			<div>
				<TextContainer
					alignment={alignment}
					delay={entranceDelay}
					transitionIn={transitionIn}
					headlineStyle={headlineStyle}
				>
					{eyebrow && (
						<div>
							<Eyebrow
								hasText={headline || text}
								alignment={alignment}
								handwritten={handwrittenEyebrow}
								className="eyebrow"
							>
								{eyebrow}
							</Eyebrow>
							{underlineColor !== 'none' && (
								eyebrowUnderline && (
									<UnderlineWrap contentBelow={headline || text || actions?.length > 0}>
										<StyledUnderline
											className='short-hr'
											altUnderline={altUnderline}
											underlineColor={underlineColors['theme' + setTheme] || underlineColors[underlineColor]}
										/>
									</UnderlineWrap>
								)
							)}
						</div>
					)}

					{headline && (
						<Headline
							as={headlineElement || headlineSize}
							headlineSize={headlineSize}
							hasText={text}
							hasEyebrow={eyebrow}
							className='headline'
						>
							{headline}
						</Headline>
					)}

					{text && text.raw && (
						<Text textSize={textSize} alignment={alignment}>
							<ContentfulRichText richText={text} />
						</Text>
					)}

					{typeof text === 'string' && (
						<Text textSize={textSize} alignment={alignment}>
							<p>{text}</p>
						</Text>
					)}

					{text && typeof text !== 'string' && !text.raw && (
						<Text textSize={textSize} alignment={alignment}>
							{text}
						</Text>
					)}

					{actions && (
						<ButtonActions buttons={actions} alignment={alignment}>
							{actions.map((action, index) => {
								if (action.__typename === 'ContentfulButton') {
									let actionTheme = 'default'
									if (action.theme === 'primary') {
										actionTheme = themes[theme].buttonTheme || 'default'
									} else if (action.theme === 'secondary') {
										actionTheme =
											themes[theme].buttonThemeSecondary || 'default'
									}
									let to = '/'
									if (action?.linkToPage?.shopifyProductId) {
										to = getProductLink(action?.linkToPage?.shopifyProductId)
									} else if (action?.linkToPage?.slug) {
										to = '/' + action.linkToPage.slug
									} else if (action?.to) {
										to = action.to
									}

									return (
										<ActionWrapper key={'button-' + index}>
											<Button
												setTheme={actionTheme}
												to={to}
												external={action.to}
												target={action.openInNewTab ? '_blank' : ''}
												title={action.label}
												name={action.label}
											>
												{action.label}
											</Button>
										</ActionWrapper>
									)
								} else {
									return (
										<ActionWrapper key={'button-' + index}>
											<CustomTextLink
												to={action.to || action.linkToPage.slug}
												external={action.to}
												target={action.openInNewTab ? '_blank' : ''}
												title={action.label}
												name={action.label}
											>
												{action.label}
											</CustomTextLink>
										</ActionWrapper>
									)
								}
							})}
						</ButtonActions>
					)}
				</TextContainer>
			</div>
		</Wrapper>
	)
}

TextLockup.defaultProps = {
	alignment: 'inherit',
	textSize: 'body',
	entranceDelay: 0,
	transitionIn: true,
}

export default TextLockup
