import React from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import ThemeSelector from 'src/components/ThemeSelector'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { MdClose } from 'react-icons/md'
import AnimateHeight from 'react-animate-height'
import RoughEdge from 'src/components/RoughEdge'
import { colors, typography } from 'src/styles'

const NotificationCollapse = styled(AnimateHeight)`
	position: relative;
	z-index: 2;
`

const Wrapper = styled(ThemeSelector)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 40px 6px;
	text-align: center;
	position: relative;
	p {
		margin: 0;
		${ typography.bodySmall }
		line-height: 1.3em;
		font-weight: ${ typography.medium };
	}
`

const CloseButton = styled(Button)`
	position: absolute;
	top: calc(50% + 2px);
	right: 15px;
	margin-right: -11px;
	transform: translateY(-50%);
	color: inherit;
	background: transparent;
	border-color: transparent;
	&:hover {
		color: inherit;
		border-color: transparent;
		background: transparent;
		opacity: 1;
	}
`

const StyledRoughEdge = styled(RoughEdge)`
	svg {
		transition: height 300ms ease-in-out;
		${ ({ collapsed }) => collapsed ? 'height: 0 !important;' : '' }
	}
	path {
		fill: ${ colors.mainColor };
	}
`

const NotificationBanner = ({ className, setTheme, text, closeBanner, collapsed }) => {
	if (!text) {
		return false
	}

	let bannerColor = setTheme
	if (setTheme === null) {
		bannerColor = 'mainColor'
	}

	return (
		<div style={{ position: 'relative', zIndex: 2 }}>
			<NotificationCollapse
				duration={300}
				delay={100}
				animateOpacity={false}
				height={collapsed ? 0 : 'auto'}
			>
				<Wrapper className={className} setTheme={bannerColor}>
					{text.raw ? (
						<ContentfulRichText richText={text}/>
					) : <p>{text}</p>}
					<CloseButton onClick={closeBanner} icon={<MdClose size="18"/>} shape="circle" setTheme='transparentLight' size="tiny" name="Close header banner"/>
				</Wrapper>
			</NotificationCollapse>
			<StyledRoughEdge position='bottom' delay={1} collapsed={collapsed} />
		</div>
	)
}

NotificationBanner.defaultProps = {
	setTheme: 'mainColor',
	collapsed: false
}

export default NotificationBanner
