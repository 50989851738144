import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import CartSvg from 'src/assets/images/cart-circle.svg'
import { colors, typography, animations, mq } from 'src/styles'

const Wrapper = styled.div`
	position: relative;
	width: 30px;
	height: 30px;
	transition: color ${ animations.mediumSpeed } ease-in-out;
	${ mq.mediumAndBelow } {
		width: 40px;
		height: 40px;
	}
	${ ({ scrolled, count }) => !scrolled ? `
		${ count > 0 ? `
			color: ${ colors.white };
		` : `
			color: ${ colors.pink3 };
		` }
	` : `
		${ count > 0 ? `
			color: ${ colors.white };
		` : `
			color: ${ colors.textColor };
		` }
	` }
`

const StyledCartIcon = styled(CartSvg)`
	color: inherit;
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	* {
		transition: fill ${ animations.mediumSpeed } ease-in-out;
	}
	
	${ ({ count, mobileMenuOpen, scrolled }) => count > 0 ? css`
		animation: ${ animations.rotate } 2s ease infinite;
		transform-origin: 50%;
		animation-duration: 5s;
		animation-play-state: running;
		* {
			fill: ${ colors.pink4 };
		}
	` : `
		${ !scrolled ? `
			* {
				fill: ${ colors.white };
			}
		` : `
			* {
				fill: ${ colors.pink2 };
			}
		` }
	` }
`

const CartCount = styled.span`
	position: absolute;
	color: inherit;
	position: absolute;
	top: 35%;
	left: 0;
	right: 0;
	text-align: center;
	${ typography.bodySmall }
	${ ({ count }) => count > 9 ? `
	` : '' }
	line-height: 10px;
	z-index: 3;
`

const CartIcon = ({ className, count, scrolled, mobileMenuOpen }) => (
	<Wrapper className={className} count={count} scrolled={scrolled} mobileMenuOpen={mobileMenuOpen}>
		<CartCount count={count}>{count}</CartCount>
		<StyledCartIcon count={count} scrolled={scrolled} mobileMenuOpen={mobileMenuOpen}/>
	</Wrapper>
)

CartIcon.defaultProps = {
	count: 0
}

export default CartIcon
