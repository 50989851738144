import React from 'react'
import styled from '@emotion/styled'
import themes from 'src/styles/themes'
import { rgba } from 'polished'
import { colors } from 'src/styles'

const ThemeWrapper = styled.div`
	${ ({ 'data-theme': setTheme }) => setTheme && setTheme !== 'bgColor' ? `
		background-color: ${ themes[setTheme].background };
		--bg-color: ${ themes[setTheme].background };
		--text-color: ${ themes[setTheme].color };
		--main-color: ${ themes[setTheme].hoverColor };
		color: var(--text-color);
		*::selection {
	    background: ${ rgba(themes[setTheme].hoverColor, 0.9) };
	    color: ${ themes[setTheme].background };
	  }
	  p a {
	  	border-color: ${ rgba(themes[setTheme].color, 0.25) };
	  	&:hover {
	  		border-color: ${ themes[setTheme].hoverColor };
	  	}
	  }
	  .accent-color {
	  	color: ${ colors.pink4 };
	  }
	` : '' }
`

const ThemeSelector = ({ className, setTheme = 'default', ...rest }) => {
	if (!setTheme || setTheme === null) {
		setTheme = 'default'
	}
	return (
		<ThemeWrapper
			data-theme={setTheme}
			className={className}
			{...rest}
		/>
	)
}

export default ThemeSelector
