import React from 'react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import RoughEdgeTopSvg from 'src/assets/images/rough-edge-top.svg'
import RoughEdgeLeftSvg from 'src/assets/images/rough-edge-left.svg'
import RoughEdgeBottomSvg from 'src/assets/images/rough-edge-bottom.svg'
import { util } from 'src/styles'

const horizontalTransform = keyframes`
	0% {
		transform: translate3d(0%, 0, 0);
	}
	100% {
		transform: translate3d(-100%, 0, 0);
	}
`

const Wrapper = styled.div`
	position: absolute;
	left: 0;
	width: 100%;
	overflow: hidden;
	display: flex;
	justify-content: flex-start;
	white-space: nowrap;
	transform-style: preserve-3d;
	svg {
		flex-shrink: 0;
		display: block;
		width: 100%;
		${ util.responsiveStyles('height', 14, 12, 10, 8) }
	}
	${ ({ position, roughEdgeClip }) => position === 'top' ? `
		${ roughEdgeClip ? `
			top: -2px;
			z-index: 3;
		` : `
			bottom: calc(100% - 2px);
		` }
	` : `
		top: calc(100% - 2px);
	` }

	${ ({ position, roughEdgeClip }) => position === 'left' ? `
		right: calc(100% - 2px);
		svg {
			flex-shrink: 0;
			display: block;
			width: 100%;
			${ util.responsiveStyles('height', 14, 12, 10, 8) }
		}
	` : `
	` }
`

const AnimationWrapper = styled.div`
	transform: translateX(0%);
	animation-fill-mode: forwards;
	animation: ${ horizontalTransform } 27s linear infinite;
	will-change: transform;
	transform-style: preserve-3d;
	animation-delay: ${ ({ delay }) => -2 * delay }s;
	width: calc(200% + 2px);
	flex-shrink: 0;
	flex-grow: 0;
	margin: 0 -1px;
	animation-direction: ${ ({ reverse }) => reverse ? 'reverse' : 'normal' };
`

const RoughEdge = ({ className, position = 'top', delay = 0, shape, roughEdgeClip }) => (
	<Wrapper className={'rough-edge ' + className} position={position} roughEdgeClip={roughEdgeClip}>
		{position === 'top' && shape !== 'bottom' ? (
			<>
				<AnimationWrapper delay={delay} reverse={delay % 2}><div><RoughEdgeTopSvg/></div></AnimationWrapper>
				<AnimationWrapper delay={delay} reverse={delay % 2}><div><RoughEdgeTopSvg/></div></AnimationWrapper>
				<AnimationWrapper delay={delay} reverse={delay % 2}><div><RoughEdgeTopSvg/></div></AnimationWrapper>
			</>
		) : (
			<>
				<AnimationWrapper delay={delay} reverse={delay % 2}><div><RoughEdgeBottomSvg/></div></AnimationWrapper>
				<AnimationWrapper delay={delay} reverse={delay % 2}><div><RoughEdgeBottomSvg/></div></AnimationWrapper>
				<AnimationWrapper delay={delay} reverse={delay % 2}><div><RoughEdgeBottomSvg/></div></AnimationWrapper>
			</>
		)}
	</Wrapper>
)

export default RoughEdge
